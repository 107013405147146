import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Layout } from "../components/layout"
import { Link } from "../components/Link"
import {
  Hero,
  Container,
  SectionHeader,
  FeatureHighlightGroup,
  FeatureHighlight,
} from "@revfluence/aspire-www-ui"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const SectionContentDiv = styled.div`
  margin-bottom: 32px;
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default function QandA({ data }) {
  return (
    <Layout>
      <SEO
        title={`2020 Holiday campaigns questions & answers`}
        description="The holidays are full of surprises — and during a year headlined by a pandemic, nothing is for sure. That's why we're here to answer all your burning questions."
        url="holiday.aspireiq.com/q-and-a"
      />
      <Hero size="sm">{null}</Hero>
      <Container>
        <PageSection>
          <SectionHeader
            Cta={() => null}
            headline="Got a question? We've got the answer"
            Copy={() => (
              <div>
                <SectionContentDiv>
                  The holidays are full of surprises — and during a year
                  headlines by a pandemic, nothing is for sure. That's why we're
                  answering all your burning questions in our coffee &amp;
                  community series.
                </SectionContentDiv>
                <SectionContentDiv>
                  Magda Houalla, Director of Marketing Strategy, and Madison
                  Smith, Senior Marketing Strategist, listen to brand's
                  challenges and walk them through their recommendations. Tune
                  in to learn:
                </SectionContentDiv>
              </div>
            )}
            position="center"
          />
        </PageSection>
        <PageSection>
          <FeatureHighlightGroup maxColumnCount={3}>
            <FeatureHighlight
              icon="list-ol"
              copy="The 3 stages of planning:<br />prepare, aware, convert"
            />
            <FeatureHighlight
              icon="smile"
              copy="How to be sympathetic to customers with everything going on"
            />
            <FeatureHighlight
              icon="expand-arrows"
              copy="Why Instagram isn't the only platform you should focus on"
            />
          </FeatureHighlightGroup>
        </PageSection>
        <PageSection>
          {/* <Link to="https://discover.aspireiq.com/coffee-shop/"> */}

          <Helmet>
            <script
              src="https://fast.wistia.com/embed/medias/1qi87dww8x.jsonp"
              async
            ></script>
            <script
              src="https://fast.wistia.com/assets/external/E-v1.js"
              async
            ></script>
          </Helmet>
          <div
            className="wistia_responsive_padding"
            style={{
              padding: "56.25% 0 0 0",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <div
                className="wistia_embed wistia_async_1qi87dww8x videoFoam=true"
                style={{
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: "100%",
                    left: 0,
                    // opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    transition: "opacity 200ms",
                    width: "100%",
                  }}
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/1qi87dww8x/swatch"
                    style={{
                      filter: "blur(5px)",
                      height: "100%",
                      objectFit: "contain",
                      width: "100%",
                    }}
                    alt=""
                    aria-hidden="true"
                    // onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </Link> */}
        </PageSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    communityAndCoffee: file(
      relativePath: { regex: "/community-and-coffee/" }
    ) {
      childImageSharp {
        fixed(width: 640, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
